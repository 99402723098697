import React, { useEffect, useState, useCallback } from 'react';
import Header from '../components/Header';
import RealTimeSideNav from '../components/RealTimeSideNav';
import SessionExpiredAlert from '../components/SessionExpiredAlert';
import Footer from '../components/Footer';
import { Base_URL } from '../constants';
import axios from 'axios';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import BarChartComponent from '../components/AI Troubleshoot/BarChartComponent';
import LineChartComponent from '../components/AI Troubleshoot/LineChartComponent';
import DataGridComponent from '../components/AI Troubleshoot/DataGridComponent';
import { Modal, Box, Typography, TextField, Button, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Select, MenuItem, InputLabel, Tooltip, IconButton, Tab, Tabs, CircularProgress } from '@mui/material';
import useFullPageLoader from '../components/hooks/useFullPageLoader';
import { DataGrid } from '@mui/x-data-grid';
import { useLocation } from 'react-router-dom';
import utc from 'dayjs/plugin/utc';
import { FcInfo } from 'react-icons/fc';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from 'recharts';

dayjs.extend(utc);

function TabPanel({ children, value, index }) {
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const RealTimeTroubleshoot = () => {
  const location = useLocation();

  const [value, setValue] = useState(0);
  const handleTabChange = (event, newValue) => setValue(newValue);

  const extractUploadTag = () => {
    const path = location.pathname;
    const parts = path.split('/');
    return parts[parts.length - 1];
  };

  const [errorGraphData, setErrorGraphData] = useState([]);
  const [logSrcData, setLogSrcData] = useState([]);
  const [topBucket, setTopBucket] = useState("");
  const tb_time = dayjs(topBucket).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const [openlogevent, setOpenLogEvent] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  const [fromDate, setFromDate] = useState(dayjs().startOf('day'));
  const [toDate, setToDate] = useState(dayjs().endOf('day'));

  const [selectedRange, setSelectedRange] = useState('3');

  const [comparisonpoint, setComparisonPoint] = useState(topBucket);
  const [offset, setOffset] = useState(selectedRange);

  const [timeRange, setTimeRange] = useState('Today');

  const [selectedSource, setSelectedSource] = useState('');

  const handleTimeRangeChange = (event) => {
    setTimeRange(event.target.value);
    const now = dayjs();
    
    if (event.target.value === 'Custom Range') {
      setShowPickers(true);
    } else {
      setShowPickers(false);
    }

    switch (event.target.value) {
      case 'Today':
        setFromDate(now.startOf('day'));
        setToDate(now.endOf('day'));
        break;
      case 'Last 12 Hours':
        setFromDate(now.subtract(12, 'hour'));
        setToDate(now);
        break;
      case 'Last 24 Hours':
        setFromDate(now.subtract(24, 'hour'));
        setToDate(now);
        break;
      case 'Last 7 Days':
        setFromDate(now.subtract(7, 'day'));
        setToDate(now);
        break;
      default:
        setFromDate(now.startOf('day'));
        setToDate(now.endOf('day'));
    }
  };

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const uploadTag = extractUploadTag();
      const response = await axios.post(Base_URL + '/users/troubleshoot_data', {
        from_ts: fromDate.toISOString(),
        to_ts: toDate.toISOString(),
        upload_tag: uploadTag
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (response.data.status === 'success') {
        setErrorGraphData(response.data.data.error_graph_data);
        setLogSrcData(response.data.data.error_log_src_data);
        setTopBucket(response.data.data.top_bucket.key_as_string);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, [fromDate, toDate]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleBarClick = (data) => {
    setSelectedData({
      time: data.payload.key_as_string,
      value: data.payload.error_count
    });
    setOpen(true);
  };

  const handleLineClick = (data) => {
    setSelectedData(data);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);
  const handleCloseLogEvent = () => setOpenLogEvent(false);

  const handleRangeChange = (event) => {
    setSelectedRange(event.target.value);
  };

  const [data, setData] = useState([]);

  const clearErrorCount = () => {
    localStorage.removeItem('error_count');
  };
  
  const handleApply = async () => {
    handleClose();
    handleCloseLogEvent();
    setLoading(true);
    const uploadTag = extractUploadTag();
    let formattedTime;
    if (!selectedData?.time) {
      formattedTime = tb_time;
    } else {
      formattedTime = dayjs(selectedData.time).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    }

    const data = JSON.stringify({
      "issues_ts": formattedTime,
      "log_source": "alllogsource",
      "upload_tag": uploadTag,
      "ts_offset": selectedRange,
      "query_mode": "errormode"
    });

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: Base_URL + '/logcluster/log_analysis',
      headers: { 
        'Content-Type': 'application/json',
      },
      data: data
    };

    try {
      const response = await axios.request(config);
      if (response.data && response.data.data && Array.isArray(response.data.data)) {
        setData(response.data.data);
        const utcDate = new Date(formattedTime);
        const localDate = utcDate.toLocaleString();
        setComparisonPoint(localDate);
        setOffset(selectedRange);
        localStorage.setItem('error_count', selectedData.value);
      } else {
        setData([]);
        const utcDate = new Date(formattedTime);
        const localDate = utcDate.toLocaleString();
        setComparisonPoint(localDate);
        setOffset(selectedRange);
      }
    } catch (error) {
      console.error("Error in API call:", error);
    } finally {
      setLoading(false);
    }
  };

  const [showPickers, setShowPickers] = useState(false);
  
  useEffect(() => {
    const handleUnload = () => {
      clearErrorCount();
    };

    window.addEventListener('beforeunload', handleUnload);
  
    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, []);
  
  useEffect(() => {
    if (topBucket) {
      handleApply();
    }
  }, [topBucket]);  

  const handleApplyLogEvent = async () => {
    const dataGridElement = document.getElementById('myDataGrid');
    if (dataGridElement) {
      dataGridElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
    handleCloseLogEvent();
    setLoading(true);
    const uploadTag = extractUploadTag();
    let formattedTime;
    formattedTime = dayjs(selectedData).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

    const data = JSON.stringify({
      "issues_ts": formattedTime,
      "log_source": "alllogsource",
      "upload_tag": uploadTag,
      "ts_offset": selectedRange,
      "query_mode": "errormode"
    });

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: Base_URL + '/logcluster/log_analysis',
      headers: { 
        'Content-Type': 'application/json',
      },
      data: data
    };

    try {
      const response = await axios.request(config);
      if (response.data && response.data.data && Array.isArray(response.data.data)) {
        setData(response.data.data);
        const utcDate = new Date(formattedTime);
        const localDate = utcDate.toLocaleString();
        setComparisonPoint(localDate);
        setOffset(selectedRange);
      } else {
        setData([]);
        const utcDate = new Date(formattedTime);
        const localDate = utcDate.toLocaleString();
        setComparisonPoint(localDate);
        setOffset(selectedRange);
      }
    } catch (error) {
      console.error("Error in API call:", error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      field: 'Pattern',
      headerClassName: 'super-app-theme--header',
      headerName: 'Pattern',
      flex: 3,
      cellClassName: 'word-wrap',
      renderCell: (params) => {
        const fullMessage = params.row.Pattern || '';
        const truncatedMessage = fullMessage.length > 40 ? fullMessage.substring(0, 40) + '...' : fullMessage;
  
        return (
          <Tooltip title={fullMessage}>
            <span>{truncatedMessage}</span>
          </Tooltip>
        );
      },
    },
    { field: 'count_of_pattern_Previous', headerClassName: 'super-app-theme--header', headerName: 'Prior Comparison Point', flex: 2 },
    { field: 'count_of_pattern_Current', headerClassName: 'super-app-theme--header', headerName: 'Post Comparison Point', flex: 2 },
    { field: 'Difference', headerClassName: 'super-app-theme--header', headerName: 'Difference', flex: 1.5 },
    { field: 'log_source', headerClassName: 'super-app-theme--header', headerName: 'Source', flex: 2, cellClassName: 'word-wrap' },
    {
      field: 'tags',
      headerName: 'Tags',
      flex: 2,
      headerClassName: 'super-app-theme--header',
      cellClassName: (params) => {
        let className = 'word-wrap ';

        const percentChange = params.row.Percent_change;
        const copPrev = params.row.count_of_pattern_Previous;
        if (copPrev === 0) {
          className += 'tag-increasing';
        } else if (percentChange > 0) {
          className += 'tag-new';
        } else if (percentChange === 0) {
          className += 'tag-unchanged';
        } else if (percentChange < 0 && percentChange !== -100) {
          className += 'tag-decreasing';
        } else if (percentChange === -100) {
          className += 'tag-disappeared';
        }

        return className;
      },
      valueGetter: (params) => {
        const percentChange = params.row.Percent_change;
        const copPrev = params.row.count_of_pattern_Previous;

        if (copPrev === 0) {
          return 'New';
        } else if (percentChange > 0) {
          return 'Increasing';
        } else if (percentChange === 0) {
          return 'Unchanged';
        } else if (percentChange < 0 && percentChange !== -100) {
          return 'Decreasing';
        } else if (percentChange === -100) {
          return 'Disappeared';
        } else {
          return '';
        }
      },
    },
    { field: 'Percent_change', headerClassName: 'super-app-theme--header', headerName: 'Change%', flex: 1.1 }
  ];

  const rows = data.map((item, index) => ({
    id: index,
    ...item
  }));

  const transformedLogSrcData = logSrcData.flatMap(src => 
    src.hourly_trends.map(bucket => ({
      time: bucket.key_as_string,
      [src.key]: bucket.error_count
    }))
  );

  const [selectedTag, setSelectedTag] = useState('');

  const handleTagChange = (tag) => {
    setSelectedTag(tag);
  };

  const rowsWithIds = data.map((row, index) => ({
    id: index,
    ...row,
  }));

  const filteredRows = rowsWithIds.filter((row) => {
    const percentChange = row.Percent_change;
    const copPrev = row.count_of_pattern_Previous;
  
    let matchesTag = true;
    if (selectedTag !== '') {
      matchesTag =
        (selectedTag === 'New' && copPrev === 0) ||
        (selectedTag === 'Increasing' && percentChange > 0 && copPrev !== 0) ||
        (selectedTag === 'Unchanged' && percentChange === 0 && copPrev !== 0) ||
        (selectedTag === 'Decreasing' && percentChange < 0 && percentChange !== -100 && copPrev !== 0) ||
        (selectedTag === 'Disappeared' && percentChange === -100);
    }
  
    let matchesSource = true;
    if (selectedSource !== '') {
      matchesSource = row.log_source === selectedSource;
    }
  
    return matchesTag && matchesSource;
  });  

  const [logEvents, setLogEvents] = useState([]);

  const handleLogEvent = async () => {
    const uploadTag = extractUploadTag();
    let data = JSON.stringify({
      "from_ts": fromDate.toISOString(),
      "to_ts": toDate.toISOString(),
      "log_source": "alllogsource",
      "upload_tag": uploadTag,
      "query_mode": "errormode"
    });

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: Base_URL + '/logcluster/log_events',
        headers: { 
            'Content-Type': 'application/json',
        },
        data: data
    };

    try {
        const response = await axios.request(config);
        const formattedData = response.data.data.map((element) => ({
          ...element,
          timestamp: new Date(element.timestamp).toLocaleString(),
        }));
        setLogEvents(formattedData || []);
    } catch (error) {
        console.error('Error fetching log events:', error);
    } finally {
        setLoading(false);
    }
  };

  useEffect(() => {
    if (fromDate, toDate) {
      handleLogEvent();
    }
  }, [fromDate, toDate]);

  const handleReloadAnalysis = (row) => {
    setSelectedData(row.timestamp);
    setOpenLogEvent(true);
  };

  const newcolumns = [
    { field: 'timestamp', headerClassName: 'super-app-theme--header', headerName: 'First Occurrence', flex: 1.3, cellClassName: 'word-wrap' },
    { field: 'message', headerClassName: 'super-app-theme--header', headerName: 'Description', flex: 2, cellClassName: 'word-wrap',
      renderCell: (params) => {
        const fullMessage = params.row.message || '';
        const truncatedMessage = fullMessage.length > 40 ? fullMessage.substring(0, 40) + '...' : fullMessage;
  
        return (
          <Tooltip title={fullMessage}>
            <span>{truncatedMessage}</span>
          </Tooltip>
        );
      },
    },
    { field: 'log_source', headerClassName: 'super-app-theme--header', headerName: 'Log Source', flex: 1 },
    { field: 'error_count', headerClassName: 'super-app-theme--header', headerName: 'Count', flex: 0.5 },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1.7,
      cellClassName: 'word-wrap',
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => (
          <Button
              variant="contained"
              color="primary"
              onClick={() => handleReloadAnalysis(params.row)}
          >
              Set Comparison Point
          </Button>
      ),
    }
  ];

  const [eventsData, setEventsData] = useState([]);
  const [sessionId, setSessionId] = useState('');
  const [pieData, setPieData] = useState([]);
  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  const [selectedEventDetails, setSelectedEventDetails] = useState(null);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [detailedEventsData, setDetailedEventsData] = useState([]);
  
  const handleViewDetails = (row) => {
    const eventId = row.eventId;
    const detailedData = detailedEventsData.find(event => Object.keys(event)[0] === eventId);
    
    if (detailedData) {
      setSelectedEventDetails(detailedData[eventId]);
      setDetailsModalOpen(true);
    }
  };
  
  const [reset, setReset] = useState(false);
  const handleReset = () => {
    setPieData([]);
    setEventsData([]);
    setDetailedEventsData([]);
    fetchWindowsEvents();
  };
  
    const fetchWindowsEvents = async () => {
      try {
        const uploadTag = extractUploadTag();
        let data = JSON.stringify({
          "upload_tag": uploadTag,
          "from_ts": fromDate.toISOString(),
          "to_ts": toDate.toISOString()
        });
        
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: Base_URL + '/logcluster/windows_events_analysis',
          headers: { 
            'Content-Type': 'application/json', 
          },
          data: data
        };
  
        const response = await axios(config);
        const flattenedData = [];
        const categoryCounts = {};
  
        setSessionId(response.data.req_session_id);
  
        response.data.summary_data.forEach(event => {
          flattenedData.push({
            id: `${event.event_id}-${event.first_occurrence_timestamp}`,
            eventId: event.event_id,
            message: event.Message,
            timestamp: new Date(event.first_occurrence_timestamp).toLocaleString(),
            occurrences: event.total_count,
            category: event.category,
            description: event.description
          });
  
          if (categoryCounts[event.category]) {
            categoryCounts[event.category] += event.total_count;
          } else {
            categoryCounts[event.category] = event.total_count;
          }
        });
  
        const pieDataArray = Object.keys(categoryCounts).map(category => ({
          name: category,
          value: categoryCounts[category]
        }));
  
        setPieData(pieDataArray);
        setEventsData(flattenedData);
        setDetailedEventsData(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    useEffect(() => {
    fetchWindowsEvents();
  }, [fromDate, toDate]);  

  const getCategorySummary = (data) => {
    const summary = data.reduce((acc, event) => {
      const category = event.category;
      if (!acc[category]) {
        acc[category] = 0;
      }
      acc[category] += event.doc_count;
      return acc;
    }, {});

    return Object.keys(summary).map((key) => ({
      name: key,
      value: summary[key],
    }));
  };

  const pieChartData = getCategorySummary(eventsData);

  // const win_columns = [
  //   { field: 'event_id', headerClassName: 'super-app-theme--header', headerName: 'Event ID', flex: 0.5, cellClassName: 'word-wrap' },
  //   { field: 'Message', headerClassName: 'super-app-theme--header', headerName: 'Message', flex: 2, cellClassName: 'word-wrap' },
  //   { field: 'first_occurrence_timestamp', headerClassName: 'super-app-theme--header', headerName: 'First Occurrence', flex: 1, cellClassName: 'word-wrap' },
  //   { field: 'doc_count', headerClassName: 'super-app-theme--header', headerName: 'Doc Count', flex: 0.5, cellClassName: 'word-wrap' },
  //   { field: 'category', headerClassName: 'super-app-theme--header', headerName: 'Category', flex: 1, cellClassName: 'word-wrap' },
  // ];
  const viewdetails_columns = [
    // { field: 'Message', headerName: 'Message', width: 300 },
    { field: 'first_occurrence_timestamp', headerClassName: 'super-app-theme--header', headerName: 'First Occurrence', flex: 1.2, cellClassName: 'word-wrap'},
    { field: 'category', headerClassName: 'super-app-theme--header', headerName: 'Category', flex: 2.5, cellClassName: 'word-wrap'},
    { field: 'description', headerClassName: 'super-app-theme--header', headerName: 'Description', flex: 2.5, cellClassName: 'word-wrap'},
    { field: 'doc_count', headerClassName: 'super-app-theme--header', headerName: 'Occurrences', flex: 1 },
  ];

  const win_columns = [
    // { field: 'eventId', headerName: 'Event ID', width: 150 },
    { field: 'timestamp', headerClassName: 'super-app-theme--header', headerName: 'First Occurrence', flex: 1, cellClassName: 'word-wrap',
      renderCell: (params) => {
        const fullMessage = params.row.timestamp || '';
        const truncatedMessage = fullMessage.length > 25 ? fullMessage.substring(0, 25) + '...' : fullMessage;
  
        return (
          <Tooltip title={fullMessage}>
            <span>{truncatedMessage}</span>
          </Tooltip>
        );
      },
     },
    { field: 'description', headerClassName: 'super-app-theme--header', headerName: 'Description', flex: 2, cellClassName: 'word-wrap',
      // renderCell: (params) => {
      //   const fullMessage = params.row.description || '';
      //   const truncatedMessage = fullMessage.length > 20 ? fullMessage.substring(0, 20) + '...' : fullMessage;
  
      //   return (
      //     <Tooltip title={fullMessage}>
      //       <span>{truncatedMessage}</span>
      //     </Tooltip>
      //   );
      // },
     },
    // { field: 'category', headerClassName: 'super-app-theme--header', headerName: 'Category', flex: 1, cellClassName: 'word-wrap',
    //   renderCell: (params) => {
    //     const fullMessage = params.row.category || '';
    //     const truncatedMessage = fullMessage.length > 20 ? fullMessage.substring(0, 20) + '...' : fullMessage;
  
    //     return (
    //       <Tooltip title={fullMessage}>
    //         <span>{truncatedMessage}</span>
    //       </Tooltip>
    //     );
    //   },
    //  },
    { field: 'occurrences', headerClassName: 'super-app-theme--header', headerName: 'Count', flex: 0.2, cellClassName: 'word-wrap' },
    {
      field: 'details',
      headerName: 'Details',
      flex: 0.8,
      cellClassName: 'word-wrap',
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleViewDetails(params.row)}
          sx={{ fontSize: '0.55rem' }}
        >
          Details
        </Button>
      ),
    } ,
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      cellClassName: 'word-wrap',
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleReloadAnalysis(params.row)}
          sx={{ fontSize: '0.55rem' }}
        >
          Set Comparison
        </Button>
      ),
    }    
  ];

  const handlePieChartClick = async (data) => {
    try {
      const uploadTag = extractUploadTag();
      const requestData = JSON.stringify({
        "upload_tag": uploadTag,
        "from_ts": fromDate.toISOString(),
        "to_ts": toDate.toISOString(),
        "req_sessionid": sessionId,
        "cat_filter": data.name
      });
      
      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: Base_URL + '/logcluster/windows_events_analysis',
        headers: { 
          'Content-Type': 'application/json', 
        },
        data: requestData
      };
  
      const response = await axios(config);
      const flattenedData = [];
      const categoryCounts = {};
      
      response.data.filtered_data.forEach(eventGroup => {
        const eventId = Object.keys(eventGroup)[0];
        const events = eventGroup[eventId];
        events.forEach(event => {
          flattenedData.push({
            id: `${eventId}-${event.first_occurrence_timestamp}`,
            eventId,
            message: event.Message,
            timestamp: new Date(event.first_occurrence_timestamp).toLocaleString(),
            occurrences: event.doc_count,
            category: event.category,
            description: event.description
          });
          if (categoryCounts[event.category]) {
            categoryCounts[event.category] += event.doc_count;
          } else {
            categoryCounts[event.category] = event.doc_count;
          }
        });
      });
  
      const pieDataArray = Object.keys(categoryCounts).map(category => ({
        name: category,
        value: categoryCounts[category]
      }));
  
      setPieData(pieDataArray);
      setEventsData(flattenedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <SessionExpiredAlert />
      <RealTimeSideNav />
      <Header />
      <div>
        <div className="content-wrapper" style={{ height: '1600px' }}>
          <br />
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '16px' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <FormControl sx={{ minWidth: 200 }}>
                <Select
                  value={timeRange}
                  onChange={handleTimeRangeChange}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Time Range' }}
                >
                  <MenuItem value="Today">Today</MenuItem>
                  <MenuItem value="Last 12 Hours">Last 12 Hours</MenuItem>
                  <MenuItem value="Last 24 Hours">Last 24 Hours</MenuItem>
                  <MenuItem value="Last 7 Days">Last 7 Days</MenuItem>
                  <MenuItem value="Custom Range">Custom Range</MenuItem>
                </Select>
              </FormControl>
              
              <Tooltip title="Use 'Custom Range' to search outside the predefined options" placement="right">
                <IconButton>
                  <FcInfo />
                </IconButton>
              </Tooltip>
            </Box>
            {showPickers && (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <DateTimePicker
                    label="From"
                    value={fromDate}
                    onChange={(newValue) => setFromDate(newValue)}
                    maxDate={toDate}
                    renderInput={(params) => <TextField {...params} sx={{ marginRight: 2 }} />}
                  />
                  <DateTimePicker
                    label="To"
                    value={toDate}
                    onChange={(newValue) => setToDate(newValue)}
                    maxDate={dayjs()}
                    renderInput={(params) => <TextField {...params} sx={{ marginLeft: 2 }} />}
                  />
                </div>
              </LocalizationProvider>
            )}
          </div>
          <br />
          <div className="chart-container" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className="chart-item" style={{ flex: 1, marginRight: 10, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography style={{ textAlign: 'center', fontStyle: 'italic', fontSize: '13px', fontFamily: 'Poppins, sans-serif' }}>
              Note: Click on the bar you want to set as the Comparison Point.
            </Typography>
            <BarChartComponent data={errorGraphData} onClick={handleBarClick} />
          </div>
            {/* <div className="chart-item" style={{ flex: 1, marginLeft: 10 }}>
              <LineChartComponent data={transformedLogSrcData} onClick={handleLineClick} />
            </div> */}
          </div>
          <br /><br />
          <div style={{ height: '600px', width: '100%' }}>
          <Typography style={{ marginLeft: '2%', fontSize: '22px', fontFamily: 'Poppins, sans-serif' }}><b>Pattern Comparison</b></Typography>
          <br />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
            <Button color='error' sx={{ marginLeft: '1.5%', color:'red', fontFamily: 'Poppins, sans-serif' }}>
              Comparison Point: {comparisonpoint}
            </Button>
            <Button variant='outlined' color='error' sx={{ marginRight: '1.5%', color:'red', fontFamily: 'Poppins, sans-serif' }} onClick={() => setOpen(true)}>
              Comparison Window: {offset} Hours
            </Button>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 2 }}>
            <Box sx={{ display: 'flex', gap: 0, marginLeft: '1.5%' }}>
              {['All', 'New', 'Increasing', 'Unchanged', 'Decreasing', 'Disappeared'].map((tag) => (
                <Button
                  key={tag}
                  variant={selectedTag === (tag === 'All' ? '' : tag) ? 'contained' : 'outlined'}
                  onClick={() => handleTagChange(tag === 'All' ? '' : tag)}
                  sx={{ borderRadius: 0 }}
                >
                  {tag}
                </Button>
              ))}
            </Box>

            <FormControl variant="outlined" sx={{ minWidth: 120, marginRight: '1.5%' }}>
              <InputLabel id="source-select-label">Source</InputLabel>
              <Select
                labelId="source-select-label"
                id="source-select"
                value={selectedSource}
                onChange={(e) => setSelectedSource(e.target.value)}
                label="Source"
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {logSrcData.map((source) => (
                  <MenuItem key={source.key} value={source.key}>
                    {source.key}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <br />
            <DataGridComponent rows={filteredRows} columns={columns} loading={loading} />
            <br /><br />
            <Box style={{ height: 'auto', width: '98%', marginLeft: '1%', marginTop: '-2%' }}
              sx={{
              '& .super-app-theme--header': {
                backgroundColor: '#060694',
                color: 'white',
                fontSize: '19px',
                fontSize: '14px',
                whiteSpace: 'nowrap',
                },
              }}
            >
            <div>
            <Typography style={{ fontSize: '22px', fontFamily: 'Poppins, sans-serif' }}>
            <b>Notable Events</b>
            </Typography>
              <Tabs value={value} onChange={handleTabChange} aria-label="simple tabs example">
                <Tab label="All Errors" />
                {/* <Tab label="Imp Events" /> */}
              </Tabs>

              <TabPanel value={value} index={0}>
                <div style={{ height: 400, width: '100%' }}>
                  <br />
                  <DataGrid
                    rows={logEvents}
                    columns={newcolumns}
                    pageSize={5}
                    loading={loading}
                    getRowId={(row) => `${row.timestamp}-${row.log_source}-${row.message}`}
                  />
                </div>
              </TabPanel>

              <TabPanel value={value} index={1}>
                <div style={{ display: 'flex', height: 400, width: '100%' }}>
                  <div style={{ flex: 2, marginRight: '20px', maxWidth: '800px' }}>
                    <DataGrid
                      rows={eventsData}
                      columns={win_columns}
                      pageSize={5}
                      rowsPerPageOptions={[5]}
                      disableSelectionOnClick
                      getRowId={(row) => `${row.eventId}-${row.timestamp}`}
                    />
                  </div>
                  <div style={{ flex: 1 }}>
                    <Button variant='outlined' onClick={handleReset}>Reset</Button>
                    <ResponsiveContainer width="100%" height="100%">
                      <PieChart>
                        <Pie
                          data={pieData}
                          cx="50%"
                          cy="50%"
                          outerRadius={100}
                          fill="#8884d8"
                          dataKey="value"
                          label
                          onClick={handlePieChartClick}
                          style={{ cursor: 'pointer' }}
                        >
                          {pieData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                          ))}
                        </Pie>
                        <Tooltip />
                        <Legend />
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </TabPanel>
            </div>
          </Box>
          </div>
        </div>
        <Modal open={open} onClose={handleClose}>
          <Box sx={{ width: 350, margin: 'auto', padding: 2, backgroundColor: 'white', marginTop: '15%' }}>
            <Typography variant="h6">Select Time Range</Typography>
            <FormControl component="fieldset" sx={{ marginTop: 2 }}>
              <FormLabel component="legend">Before-After</FormLabel>
              <RadioGroup row value={selectedRange} onChange={handleRangeChange}>
                <FormControlLabel value="3" control={<Radio />} label="3 Hours" />
                <FormControlLabel value="6" control={<Radio />} label="6 Hours" />
                <FormControlLabel value="9" control={<Radio />} label="9 Hours" />
              </RadioGroup>
            </FormControl>
            <Button variant="contained" onClick={handleApply} sx={{ marginTop: 2 }}>Apply</Button>
            <Button variant="contained" onClick={handleClose} sx={{ marginTop: 2, marginLeft: 1 }}>Close</Button>
          </Box>
        </Modal>
        <Modal open={openlogevent} onClose={handleCloseLogEvent}>
          <Box sx={{ width: 350, margin: 'auto', padding: 2, backgroundColor: 'white', marginTop: '15%' }}>
            <Typography variant="h6">Select Time Range</Typography>
            <FormControl component="fieldset" sx={{ marginTop: 2 }}>
              <FormLabel component="legend">Before-After</FormLabel>
              <RadioGroup row value={selectedRange} onChange={handleRangeChange}>
                <FormControlLabel value="3" control={<Radio />} label="3 Hours" />
                <FormControlLabel value="6" control={<Radio />} label="6 Hours" />
                <FormControlLabel value="9" control={<Radio />} label="9 Hours" />
              </RadioGroup>
            </FormControl>
            <Button variant="contained" onClick={handleApplyLogEvent} sx={{ marginTop: 2 }}>Apply</Button>
            <Button variant="contained" onClick={handleCloseLogEvent} sx={{ marginTop: 2, marginLeft: 1 }}>Close</Button>
          </Box>
        </Modal>
        <Modal
          open={detailsModalOpen}
          onClose={() => setDetailsModalOpen(false)}
        >
          <Box sx={{ padding: 2, maxWidth: 800, margin: 'auto', marginTop: '3%', backgroundColor: 'white'}}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography style={{ fontSize: '22px', fontFamily: 'Poppins, sans-serif' }}>
                Event Details
              </Typography>
              <Button variant='outlined' onClick={() => setDetailsModalOpen(false)}>Close</Button>
            </Box>
            {selectedEventDetails ? (
              <div style={{ height: 400, width: '100%', marginTop: '1%' }}>
                <DataGrid
                  rows={selectedEventDetails.map((detail, index) => ({
                    id: index,
                    Message: detail.Message,
                    first_occurrence_timestamp: new Date(detail.first_occurrence_timestamp).toLocaleString(),
                    category: detail.category,
                    description: detail.description,
                    doc_count: detail.doc_count
                  }))}
                  columns={viewdetails_columns}
                  pageSize={5}
                  rowsPerPageOptions={[5, 10, 20]}
                />
              </div>
            ) : (
              <Typography variant="body2">No details available.</Typography>
            )}
          </Box>
        </Modal>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default RealTimeTroubleshoot;