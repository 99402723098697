import React, { useEffect, useState } from 'react'
import wave1 from '../images/wave_latest.svg';
import login from '../images/login (1).svg';
import wowlogo from '../images/New Wowizer Logo 1.svg';
import { Button, Grid, IconButton, TextField } from '@mui/material';
import { Email } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import useFullPageLoader from '../components/hooks/useFullPageLoader';
import { Base_URL } from '../constants';
import randomstring from 'randomstring';
import RefreshIcon from '@mui/icons-material/Refresh';
import ReCAPTCHA from 'react-google-recaptcha';

const TestDrive = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [email, setEmail] = useState('');
  const [newsletter, setNewsLetter] = useState(true);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [captchaValue, setCaptchaValue] = useState('');

  const generateCaptcha = () => {
    return randomstring.generate({
      length: 6,
      charset: 'alphanumeric',
    });
  };
  
  const [captcha, setCaptcha] = useState(generateCaptcha());
  const [userCaptcha, setUserCaptcha] = useState('');
  
  const handleRefreshCaptcha = () => {
    setCaptcha(generateCaptcha());
    setUserCaptcha('');
  };  

  const generateCaptchaImage = (text) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = 150;
    canvas.height = 50;
    context.fillStyle = '#f0f0f0';
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.font = '30px Arial';
    context.fillStyle = '#000000';
    context.textAlign = 'center';
    context.textBaseline = 'middle';
    context.fillText(text, canvas.width / 2, canvas.height / 2);
    const dataUrl = canvas.toDataURL('image/png');
    
    return dataUrl;
  };

  useEffect(() => {
    const handlePopState = () => {
      handleRefreshCaptcha();
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = (e) => {
    showLoader();
    e.preventDefault();
  
    // if (userCaptcha.toLowerCase() !== captcha.toLowerCase()) {
    //   hideLoader();
    //   alert('Incorrect captcha');
    //   handleRefreshCaptcha();
    //   return;
    // }

    if (!captchaValue) {
      hideLoader();
      swal({
        title: 'Please complete the reCAPTCHA',
        icon: 'error',
      });
      return;
    }

    if (!validateEmail(email)) {
      hideLoader();
      swal({
        title: "Invalid Email Format",
        text: "Please enter a valid email address.",
        icon: "error",
      });
      return;
    }
    
    // if (agreeToTerms === false) {
    //   hideLoader();
    //   alert('Please agree to the Privacy Policy and License Terms & Conditions to sign up.');
    //   return;
    // }
    // const notallowedDomains = ['gmail.com', 'yahoo.com', 'outlook.com', 'hotmail.com'];
    // const emailDomain = email.split('@')[1];
    // if (notallowedDomains.includes(emailDomain)) {
    //   hideLoader();
    //   swal({
    //     title: "Valid Company Email is required for Sign Up",
    //     icon: "error",
    //   });
    //   return;
    // }

    var data = JSON.stringify({
      "email": email
    });

    var config = {
      method: 'post',
      url:  Base_URL + '/trialdrive/reqtrial/',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };

    axios(config)
    .then(function (response) {
      hideLoader();
      swal("WoWizer TPM Direct login link send!", "This access is valid for 7 days. Go ahead and check your inbox", "success").then(function () {
        window.location.href = 'https://wowizer.ai/blog/introducing-wowizer-telemetry-and-performance-monitoring-tpm-for-qlik-sense/'
      });
    })
    .catch(function (error) {
      hideLoader();
      swal({
        text: error.response.data.message,
        // icon: "warning",
        buttons: {
          register: {
            text: "Register",
            value: "register",
            className: "black-text-button",
          },
          cancel: "Cancel",
        },
      })
      .then((value) => {
        if (value === "register") {
          window.location.href = "/register";
        } else {
          
        }
      });      
    });
  };

  return (
    <>
    <img className="wave" src={wave1} />
    <div className="container-login">
        <div className="img">
          <img className='login-image' src={login} />
        </div>
        <div className="login-content">
          <form className='form-class-login' onSubmit={handleSubmit}>
            <div className="row">
              <div className="col">
                <img className='actionate-img' src={wowlogo} width="90%" style={{ marginLeft: '-2%' }} />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <br />
                <div style={{ fontSize: '20px', color: '#000080' }}>Take control of Qlik user experience and drive adoption.</div>
                <br />
                <TextField
                  id="email"
                  label="Email"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  InputProps={{
                    startAdornment: <Email />,
                  }}
                  required
                />
              </div>
            </div>
            <br />
            <div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
              <div className="col" style={{ display: 'flex', justifyContent: 'center' }}>
                <ReCAPTCHA
                  sitekey="6LeGSykqAAAAABbbtnIqCUP3Dea1zQNVdQ1aijKz"
                  onChange={(value) => setCaptchaValue(value)}
                />
              </div>
            </div>
            {/* <div className="row">
              <div className="col">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    type="checkbox"
                    checked={agreeToTerms}
                    onChange={() => setAgreeToTerms(!agreeToTerms)}
                    style={{ marginRight: '8px' }}
                  />
                  <span>
                    By signing up, you agree to the <a href='https://wowizer.ai/privacy-policy/' target="_blank">Privacy Policy</a> and <a href='https://wowizer.ai/eula/' target="_blank">License Terms & Conditions</a>
                  </span>
                </div>
              </div>
            </div> */}
            {/* <div className="row">
              <div className="col">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    type="checkbox"
                    checked={newsletter}
                    onChange={(e) => setNewsLetter(!newsletter)}
                    style={{ marginRight: '8px' }}
                  />
                  <span>
                    You'll receive our insightful newsletter, but feel free to unsubscribe anytime.
                  </span>
                </div>
              </div>
            </div> */}
            {/* <Grid item xs={6}>
              <TextField
                id="captcha"
                label="Enter Captcha"
                variant="outlined"
                fullWidth
                margin="normal"
                value={userCaptcha}
                onChange={(e) => setUserCaptcha(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <React.Fragment>
                      <img
                        src={generateCaptchaImage(captcha)}
                        alt="captcha"
                        style={{ height: '40px' }}
                      />
                      <IconButton onClick={handleRefreshCaptcha}>
                        <RefreshIcon />
                      </IconButton>
                    </React.Fragment>
                  ),
                }}
                sx={{ width: 'calc(60% - 16px)' }}
                required
              />
            </Grid> */}
            <div className="row">
              <div className="col">
                <br />
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{ borderRadius: '50px' }}
                  type='submit'
                  disabled={!captchaValue}
                >
                  Get Test Drive Link !
                </Button>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <br />
                <Link to="/" style={{ textDecoration: 'none', fontWeight: "bolder" }}>
                  Already Registered? Login
                </Link>
              </div>
              <div className="col">
                <br />
                <Link to="/register" style={{ textDecoration: 'none', fontWeight: "bolder"}}>
                    Register User
                </Link>
              </div>
            </div>
          </form>
        </div>
        {loader}
    </div>
    </>
  )
}

export default TestDrive